import * as React from "react";
import { Row, Col } from "react-bootstrap";


const Testimonial = (props) => {
  const { logo, name, testimonial, background } = props;
    const LogoElem = logo;
  return (
    <Row>
      <Col className="p-5 m-auto">
        <Row>
          <Col>
            <Row>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: 95,
                    width: 95,
                    height: 95,
                    background:"linear-gradient(293deg, #2e93e3, transparent)",
                    marginRight: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {logo && <LogoElem></LogoElem>}
                </div>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 900,
                    color: "#2179bf",
                  }}
                >
                  {name}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xl={10} md={12} sm={12}>
                <div
                  style={{
                    paddingLeft: 125,
                    fontStyle: "italic",
                    color: "#565656",
                  }}
                >
                  {testimonial}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Testimonial;
