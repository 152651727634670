import * as React from "react";
import { useState, useEffect } from "react";
import { Form, Button, Modal, ProgressBar } from "react-bootstrap";
const wizardLabels = {
  "I need money": "Why money is needed ",
  "How much do you want?:amount": "How much money is needed ",
  "My business is in": "My business is in ",
  "When did you start the business?:month": "I started in (Month) ",
  "When did you start the business?:Year": "I started in (Year) ",
  "Latest Revenue:latestRevenue": "My latest revenue ",
  "Latest Revenue:latestProfitAfterTax": "My profit after taxation ",
  "How long do you want finance for?": "How long I expect finacing for ",
  "Contact Details:name": "Name ",
  "Contact Details:email": "Email ",
  "Contact Details:telephone": "Telephone ",
};
const wizardData = [
  {
    stepTitle: "",
    title: "I need money",
    control: "radioButtons",
    controlData: [
      {
        name: "Business Expansion",
        value: "Business Expansion",
      },
      {
        name: "Buy Machinery/Vehicle",
        value: "Buy Machinery/Vehicle",
      },
      {
        name: "Other",
        value: "Other",
      },
    ],
  },
  {
    stepTitle: "",
    title: "How much do you want?",
    control: "textbox",
    controlData: [
      {
        label: "Amount",
        value: "amount",
      },
    ],
  },
  {
    stepTitle: "",
    title: "My business is in",

    control: "dropdown",
    controlData: [
      {
        name: "Food and Beverages",
        value: "Food and Beverages",
      },
      {
        name: "Manufacturing",
        value: "Manufacturing",
      },
      {
        name: "Construction",
        value: "Construction",
      },
      {
        name: "Transportation",
        value: "Transportation",
      },
      {
        name: "Salon",
        value: "Salon",
      },
      {
        name: "Digital Solutions",
        value: "Digital Solutions",
      },
      {
        name: "Others",
        value: "Others",
      },
    ],
  },
  {
    stepTitle: "",
    title: "When did you start the business?",
    control: "textbox",
    controlData: [
      {
        label: "Month",
        value: "month",
      },
      {
        label: "Year",
        value: "Year",
      },
    ],
  },
  {
    stepTitle: "",
    title: "Latest Revenue",
    control: "textbox",
    controlData: [
      {
        label: "Latest Revenue",
        value: "latestRevenue",
      },
      {
        label: "Latest profits after tax",
        value: "latestProfitAfterTax",
      },
    ],
  },
  {
    stepTitle: "",
    title: "How long do you want finance for?",
    control: "radioButtons",
    controlData: [
      {
        name: "Less than a year",
        value: "Less than a year",
      },
      {
        name: "Less than 2 years",
        value: "Less than 2 years",
      },
      {
        name: "More than 8 years",
        value: "More than 8 years",
      },
    ],
  },
  {
    stepTitle: "",
    title: "Contact Details",
    control: "textbox",
    controlData: [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Email Address",
        value: "email",
      },
      {
        label: "Telephone Number",
        value: "telephone",
      },
    ],
  },
];

const Wizard = (props) => {
  const { show, handleClose, onSuccess, onError } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepData, setCurrentStepData] = useState(0);
  const initErrors = (obj) => {
    wizardData.forEach((elem) => {
      if (elem.control == 'textbox') {
        elem.controlData.forEach((cd) => {
            obj[elem.title+":"+cd.value] = "required"
          })
      } else {
        obj[elem.title] = "required";
      }
    });
    return obj;
  };
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState(initErrors({}));
  
  useEffect(() => {
    setCurrentStepData(wizardData[currentStep]);
  }, [currentStep]);

  const calculateCurrentPercent = () => {
    return parseInt(((currentStep+1) / parseFloat(wizardData.length)) * 100);
  }

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field] && value) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const hasError = () => {
    if (wizardData[currentStep].control === 'textbox') {
      let isError = false;
      wizardData[currentStep].controlData.forEach(cd => {
        isError = isError || errors[wizardData[currentStep].title + ":" + cd.value] === 'required';
      });
      return isError;
    }
    return errors[wizardData[currentStep].title] === 'required';
  }
  
  const renderComponent = () => {
    if (currentStepData.control === 'radioButtons') {

      return currentStepData.controlData.map((radioItem, indx) => (
        <div key={`form_${indx}`}>
          <input
            type="radio"
            className="wizard-radio"
            name={currentStepData.title}
            onChange={(e) => setField(currentStepData.title, e.target.value)}
            value={radioItem.value}
            checked={form[currentStepData.title] === radioItem.value}
          />
          {radioItem.value}
        </div>
      ));
    }

    if (currentStepData.control === "textbox") {
       return currentStepData.controlData.map((radioItem, indx) => (
         <Form key={`${radioItem.value}_${indx}`}>
           <Form.Group className="mb-3" controlId={radioItem.value}>
             <Form.Label>{radioItem.label}</Form.Label>
             <Form.Control
               type="text"
               onChange={(e) =>
                 setField(
                   currentStepData.title + ":" + radioItem.value,
                   e.target.value
                 )
               }
               value={form[currentStepData.title + ":" + radioItem.value]}
               placeholder={`Enter ${radioItem.label}`}
             />
           </Form.Group>
         </Form>
       ));
    }

    if (currentStepData.control === "fileUpload") {
      return currentStepData.controlData.map((radioItem, indx) => (
        <Form key={`form_${indx}`}>
          <Form.Group className="mb-3" controlId={radioItem.value}>
            <Form.Label>{radioItem.label}</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setField(currentStepData.title, e.target.value)}
              value={form[radioItem.value]}
              placeholder={`Enter ${radioItem.label}`}
            />
          </Form.Group>
        </Form>
      ));
    }

    if (currentStepData.control === "dropdown") {
      return (
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              as="select"
              onChange={(e) => setField(currentStepData.title, e.target.value)}
              value={form[currentStepData.title] || 'select'}
            >
              <option
                  key={`form_${-1}`}
                placeholder={"Select Option"}
                disabled={true}
                value={'select'}
                > Select Option</option>
              {currentStepData.controlData.map((dropdownItem, indx) => (
                <option
                  key={`form_${indx}`}
                  placeholder={"Select"}
                  value={dropdownItem.value}
                >
                  {" "}
                  {dropdownItem.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      );
    }
  }

  return (
    <Modal
      keyboard={false}
      show={show}
      onHide={() => {
        setForm({});
        setCurrentStep(0);
        handleClose();
        setErrors(initErrors({}))
      }}
      backdrop="static"
      className="wizard"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Step {currentStep + 1} of {wizardData.length}{" "}
          {wizardData[currentStep].stepTitle &&
            ` - ${wizardData[currentStep].stepTitle}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <ProgressBar
            now={calculateCurrentPercent()}
            label={`${calculateCurrentPercent()}%`}
          />
        </div>
        <h2 className="wizard-title">{currentStepData.title}</h2>
        <div>{renderComponent()}</div>
        {/* <h5>Modal body text goes here.</h5> */}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="light"
          disabled={currentStep === 0}
          onClick={() => {
            setCurrentStep((currentStep - 1) % wizardData.length);
          }}
        >
          &lt; Back
        </Button>
        {currentStep + 1 !== wizardData.length && (
          <Button
            variant="light"
            disabled={hasError()}
            onClick={() => {
              setCurrentStep((currentStep + 1) % wizardData.length);
            }}
          >
            Continue &gt;
          </Button>
        )}
        {currentStep + 1 === wizardData.length && (
          <Button
            variant="light"
            disabled={hasError()}
            onClick={async () => {
              const submitData = {};
              for (const [key, val] of Object.entries(wizardLabels)) {
                submitData[key] = {
                  value: form[key], label: val
                };
              }
              fetch("https://contact.tarin.ie/get-started", {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(submitData),
              })
                .then(function (res) {
                  onSuccess();
                  setForm({});
                  setCurrentStep(0);
                  setErrors(initErrors({}));
                })
                .catch(function (res) {
                  console.error(res);
                  onError();
                });
            }}
          >
            Submit Details
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  ); 
}

export default Wizard;